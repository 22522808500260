.delete-address-modal {
  margin: 0 !important;
  padding: 30px 30px 15px 30px;
  border-radius: 24px;
  max-height: 100vh;
  width: 330px;
  overflow-y: auto;

  h2 {
    font-weight: bold;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 25px;
    color: #4b4b55;
  }

  h3 {
    margin-bottom: 13px;
  }

  .react-responsive-modal-closeButton {
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    margin-right: 10px;
    outline: none;
    background: rgba(75, 75, 85, 0.15);
    border-radius: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .delete-address-modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}
