.giftcards-modal {
  padding: 30px;
  border-radius: 24px;
  max-height: 100vh;
  width: 328px;
  overflow-y: auto;

  .react-responsive-modal-closeButton {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .admin-modal {
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
}

.description-text-lg {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  margin: 0 0 8px;
  padding-top: 12px;
}

.description-text-sm {
  font-size: 12px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
  color: #4b4b5580;
  margin-bottom: 18px;
}

.react-responsive-modal-closeButton {
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-right: 10px;
  outline: none;
  background: rgba(75, 75, 85, 0.15);
  border-radius: 40px;
  display: flex !important;
}
