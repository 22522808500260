.progress-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .progress-value {
    font-weight: bold;
    font-size: 13px;
    color: #4b4b55;
    margin-bottom: 5px;
  }

  .progress-container {
    display: flex;
    align-items: flex-start;
    width: 60px;
    height: 4px;
    background-color: #ebebf0;
    border-radius: 4px;

    .progress-label {
      padding: 5;
      color: #fff;
    }
  }
}

@media only screen and (max-width: 780px) {
  .progress-container {
    .progress-value {
      margin-bottom: 4px;
    }
  }
}
