.search-bar-container {
  .search-icon {
    position: relative;
    top: 12px;
    margin-left: -60px;
  }

  .textbox {
    height: 44px;
    background: #ffffff;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4b4b55;
    // padding-left: 15px;
  }

  .clearBtn {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: rgba(75, 75, 85, 0.4);
    margin-bottom: 12px;
  }
}
