.reassign-container {
  position: relative;
  z-index: 10;
  user-select: none;
  display: flex;
  justify-content: center;

  &.open {
    .reassign-header {
      justify-content: space-between;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      width: 83.3%;
      height: 50px;
    }
    .reassign-header-title {
      color: #999999;
    }
  }

  .reassign-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 8px;
    background-color: white;
    cursor: default;
    width: 83.3%;
    height: 44px;
    max-height: 50px;
    box-shadow: 0px 1px 4px rgba(75, 75, 85, 0.1);
    padding: 0 12px;
    outline: none;
    span {
      display: flex;
      margin-right: 20px;
    }
    .mobile {
      display: inline-block;
    }
    .desktop {
      display: inline-block;
    }
  }

  .reassign-list {
    position: absolute;
    z-index: 10;
    width: 83.3%;
    min-height: 50px;
    max-height: 250px;
    border: 1px solid rgb(223, 223, 223);
    border-top: none;
    border-radius: 0 0 6px 6px;
    background-color: white;
    overflow-y: scroll;
    margin-top: 45px;
    .reassign-list-item {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-shadow: none;
      text-align: left;
    }
    &::-webkit-scrollbar {
      width: 0px;
    }
    &.scroll {
      &::-webkit-scrollbar {
        width: 6px;
      }
      &::-webkit-scrollbar-track {
        background-color: #ebebf0;
        border-radius: 4px;
        margin: 4px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: white;
        border-radius: 4px;
        border: 3px solid rgba(153, 153, 153, 1);
      }
    }
  }

  .reassign-item-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    padding: 10px 12px 10px 12px;
    cursor: pointer;
    &:hover {
      background-color: rgba(75, 75, 85, 0.05);
    }
  }
}

@media only screen and (max-width: 750px) {
  .reassign-container {
    display: flex;
    flex-direction: column;
    align-items: center;

    .reassign-list {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      align-content: center;
      justify-content: center;
      z-index: 50;
      width: 83.3%;
      max-height: 250px;
      border: 1px solid rgb(223, 223, 223);
      border-top: none;
      border-radius: 0 0 6px 6px;
      background-color: white;
      overflow-y: scroll;
      overflow-x: none;
      margin-top: 0px;
      -webkit-overflow-scrolling: touch;
    }
  }
}
