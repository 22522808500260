.reassign-merch-modal {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 24px;
  height: 275px;
  width: 420px;
  overflow: visible;
  outline: none;
  .card-wrapper {
    overflow: visible;
  }
  h2 {
    padding: 30px 30px 40px 40px;
    font-weight: bold;
  }

  .button-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 20px;
    z-index: 0;
    width: 99%;
  }

  .react-responsive-modal-closeButton {
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    top: 30px;
    outline: none;
    background: rgba(75, 75, 85, 0.15);
    border-radius: 40px;
    margin-right: 20px;
  }
}

@media only screen and (max-width: 750px) {
  .reassign-merch-modal {
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 24px;
    height: 275px;
    width: 95%;
    outline: none;
    h2 {
      padding: 30px 30px 40px 30px;
      font-weight: bold;
    }

    .button-container {
      position: relative;
      top: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      z-index: 0;
      width: 99%;
    }
  }
}
