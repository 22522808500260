.group-member-page-container {
  .group-member-page-pdp-container {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    flex: 0 0 auto;
    .group-member-page-header {
      max-width: 720px;
    }
    .group-member-page-details-container {
      padding: 0 147px;
      width: 100%;
      min-height: 762px;
      display: flex;
      justify-content: center;

      &.expanded {
        .card-wrapper {
          .group-member-page-details {
            .order-artwork {
              position: absolute;
              width: 100%;
              height: 100%;
            }
            .order-details {
              visibility: hidden;
            }
          }
        }
      }
      .card-wrapper {
        position: relative;
        .group-member-page-details {
          height: 100%;
          position: relative;
          .order-artwork {
            height: 100%;
            width: 55%;
            position: relative;
          }
          .order-details {
            overflow: none;
            width: 45%;
            padding: 60px 60px 34px 60px;
          }
        }
      }
    }
  }
  button {
    height: 44px;
  }
}

@media only screen and (max-width: 768px) {
  .group-member-page-container {
    .group-member-page-pdp-container {
      display: inline-block;
      .group-member-page-details-container {
        padding: 0;
        max-height: none;
        .card-wrapper {
          height: auto;
          border-top-left-radius: 24px;
          border-top-right-radius: 24px;
          .group-member-page-details {
            border-radius: 0;
            .order-artwork {
              height: 375px;
              width: 100%;
              padding-bottom: 31px;
            }
            .order-details {
              width: 100%;
              padding: 30px;
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1024px) {
  .group-member-page-container {
    .group-member-page-pdp-container {
      .group-member-page-details-container {
        padding: 0 20px;
        .card-wrapper {
          height: auto;
          .group-member-page-details {
            .order-artwork {
              height: 500px;
              width: 100%;
              padding-bottom: 31px;
            }
            .order-details {
              padding: 34px 20px;
            }
          }
        }
      }
    }
  }
}
