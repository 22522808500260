.product-media-container {
  background: #fafafa;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  margin-top: 5px;
}

.product-list-container {
  box-sizing: border-box;
  border: 1px solid #dddddd;
  background: #fafafa;
  border-radius: 12px;
  width: 100%;
  overflow-x: hidden;
}
