.order-list-container {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;

  table {
    .actions {
      button {
        height: 36px;
        width: 80px;
        font-size: 12px;
      }
    }
  }
}

.order-list {
  .name,
  .creationDate,
  .totalPrice {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #4b4b55;
    text-align: left;
  }
}
