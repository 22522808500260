.badge {
  border-radius: 100px;
  font-style: normal;
  font-weight: bold;
  font-size: 10px;
  display: inline-block;
  align-items: center;
  text-align: center;
  letter-spacing: 0.03em;
  text-transform: uppercase;
  padding: 6px 13px;
  justify-content: center;

  &.active {
    color: #1f823a;
    background: rgba(26, 200, 22, 0.3);
  }

  &.draft {
    color: #4b4b55;
    background: rgba(75, 75, 85, 0.3);
  }

  &.closed {
    color: #e2543e;
    background: rgba(226, 84, 62, 0.3);
  }

  &.error {
    color: #e2543e;
    background: rgba(226, 84, 62, 0.3);
  }

  &.issue {
    color: #e2543e;
    background: rgba(226, 84, 62, 0.3);
  }

  &.unfulfilled {
    color: #ff9000;
    background: rgba(233, 185, 63, 0.2);
  }

  &.pending {
    color: #ff9000;
    background: rgba(233, 185, 63, 0.2);
  }

  &.deleted {
    color: #9a0fdb;
    background: rgba(154, 15, 219, 0.3);
  }
}

@media only screen and (max-width: 800px) {
  .badge {
    &.no-text-mobile {
      span {
        display: none;
      }
      width: 12px;
      height: 12px;
      padding: 0;
    }
  }
}
