header.admin {
  height: 117px;

  .title {
    h1 {
      color: #4b4b55;
      font-weight: bold;
      margin-right: 12px;
    }
    .edit {
      margin-bottom: 6px;
      box-shadow: none;
      border-radius: 0;
      border: none;
      padding: 0;
    }
  }

  .subtitle {
    p {
      font-size: 14px;
      color: rgba(75, 75, 85, 0.5);
      font-weight: 500;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-between;

    button {
      margin-left: 15px;
    }
  }
}

@media only screen and (max-width: 768px) {
  header.admin {
    height: 87px;
    .title-container {
      padding-left: 1.75rem;
    }
  }
}
