.dd-container {
  position: relative;
  user-select: none;
  display: flex;
  justify-content: center;

  &.open {
    .dd-header {
      color: rgba(75, 75, 85, 0.5);
      justify-content: flex-end;
      border-bottom: none;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .dd-header {
    position: relative;
    border: 1px solid rgb(223, 223, 223);
    background-color: white;
    cursor: default;
    width: 100%;
    box-shadow: 0px 1px 4px rgba(75, 75, 85, 0.1);
    outline: none;
    span {
      display: flex;
      margin-right: 20px;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: inline-block;
    }
  }

  .dd-header-title {
    margin-right: 4px;
  }

  .dd-list {
    position: absolute;
    z-index: 10;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 6px;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    background-color: white;
    -webkit-overflow-scrolling: touch;

    .dd-list-title {
      display: block;
      width: 100%;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      text-align: right;
      line-height: 15px;
      color: rgba(75, 75, 85, 0.4);
    }

    .dd-list-item {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-shadow: none;
      text-align: right;
      height: 24px;
    }
  }
}

@media only screen and (max-width: 780px) {
  .dd-container {
    width: 36px;
    &.open {
      .dd-header {
        justify-content: center;
      }
    }
    .dd-header {
      height: 36px;
      width: 36px;
      padding: 0;
      .dd-header-title {
        display: none;
      }
      .desktop {
        display: none;
      }
      .mobile {
        display: inline-block;
        height: 15px;
        width: 15px;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
    .dd-list {
      right: 0;
    }
  }
}

.action-tooltip .action-tooltiptext {
  visibility: hidden;
  background-color: rgba(75, 75, 85, 1);
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  transition-delay: 2s;
  position: absolute;
  z-index: 1;
}

.action-tooltip:hover .action-tooltiptext {
  visibility: visible;
}
