.login-wrapper {
  width: 375px;
  height: 589px;

  &.password-recover {
    height: 504px;
    width: 375px;

    .login-body {
      padding: 20px 30px 18px 30px;
    }
  }
  .login-header {
    // flex: 1;
    height: 260px;
    padding: 30px;
    background-color: rgba(43, 43, 49, 1); // Placeholder
    background-repeat: round;
    border-radius: 24px 24px 0 0;
  }

  .login-body {
    // flex: 1;
    padding: 20px 30px 30px 30px;

    .inputs-container {
      margin-bottom: 30px;
    }
  }
}

@media only screen and (min-height: 601px) and (max-width: 600px) {
  .login-wrapper {
    width: 100%;
    height: 100%;

    &.password-recover {
      height: 100%;
      width: 100%;

      .card-wrapper {
        border-radius: 0;
      }
    }
    .card-wrapper {
      border-radius: 0;
      .login-header {
        border-radius: 0;
        background-position: 0 0;
        background-size: cover;
      }
      .login-body {
        height: 50%;

        .inputs-container {
          margin-bottom: 50px;
        }
      }
    }
  }
}

@media only screen and (max-height: 600px) {
  .login-wrapper {
    height: 100%;
    width: 100%;
    .login-header {
      // height: 406px;
      border-radius: 0;
      background-position: 0 0;
      background-size: cover;
    }

    .login-body {
      .inputs-container {
        margin-bottom: 20px;
      }
    }
  }
}
