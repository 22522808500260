.filter-container.status {
  margin-left: 20px;
}

.filter-container {
  position: relative;
  user-select: none;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  &.open {
    .filter-header {
      color: rgba(75, 75, 85, 0.5);
      justify-content: center;
      width: auto;
    }
  }

  .filter-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid rgb(223, 223, 223);
    background-color: white;
    cursor: default;
    width: auto;
    height: 36px;
    box-shadow: 0px 1px 4px rgba(75, 75, 85, 0.1);
    padding: 0 12px;
    outline: none;
    &.status-orders-page {
      border-radius: 6px 0 0 6px;
    }
    &.status-admin {
      border-radius: 6px;
    }
    &.admin-orders-page {
      border-radius: 0 6px 6px 0;
    }
    &.super-admin-orders {
      border-radius: 0 0px 0px 0;
    }
    &.assigned-orders-page {
      border-radius: 0 6px 6px 0;
    }
    span {
      display: flex;
      margin-right: 20px;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: inline-block;
    }
  }

  .filter-header-title {
    margin-right: 6px;
    color: #999999;
    &.chosen {
      color: #4b4b55;
    }
  }

  .filter-list {
    max-height: 60vh;
    overflow-y: scroll;
    display: flex;
    flex: auto;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    min-width: 110%;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 6px;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    background-color: white;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
      border-radius: 5px;
      margin-top: 35px;
      margin-bottom: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgba(153, 153, 153, 1);
      border-radius: 4px;
      border: 3px solid rgba(153, 153, 153, 1);
    }

    .filter-list-title {
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      color: rgba(75, 75, 85, 0.4);
    }

    .filter-list-item {
      display: inline-block;
      overflow: hidden;
      font-size: 13px;
      white-space: nowrap;
      text-overflow: ellipsis;
      box-shadow: none;
      text-align: left;
    }
  }
}

@media only screen and (max-width: 800px) {
  .filter-dropdowns {
    margin-left: 5px;
  }
  .filter-container.assigned {
    justify-content: flex-end;
  }
  .filter-container {
    &.open {
      .filter-header {
        justify-content: center;
      }
    }
    .filter-header {
      height: 34px;
      width: 100%;
      padding: 0 6px;
      .filter-header-title {
        display: inline-block;
      }
      .desktop {
        display: none;
      }
      .mobile {
        display: inline-block;
        height: 15px;
        width: 15px;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
