.controlled-input-container {
  position: relative;
  .controlled-input {
    &.no-label {
      padding: 12px;
    }
    border: 1px solid #dddddd;
    border-radius: 8px;
    position: relative;
    font-size: 14px;
    background-color: #fff;
    border: 1px solid #dddddd;
    border-radius: 8px;
    color: #4b4b55;
    font-weight: 500;
    font-size: 14px;
    padding: 20px 0 7px 15px;
    font-style: normal;
    outline: none;

    &.input-type-two {
      padding: 0.75rem;
    }

    &.error {
      border: 1px solid rgba(198, 23, 23, 0.5);
    }

    &::placeholder {
      color: rgba(75, 75, 85, 0.5);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }

    &:invalid {
      background-color: rgba(198, 23, 23, 0.16);
      border: 1px solid rgba(198, 23, 23, 0.5);
    }
  }
  .controlled-input-textarea {
    padding: 0.75rem;
    &::placeholder {
      color: rgba(75, 75, 85, 0.5);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
    }
  }
  .textbox-label {
    position: absolute;
    font-size: 11px;
    color: rgba(75, 75, 85, 0.5);
    margin-left: 15px;
    margin-top: 7px;
    z-index: 2;
    &.empty {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      top: 9px;
    }
  }

  &.error {
    svg {
      position: absolute;
      color: #c61717;
      right: 12px;
      top: 13px;
      font-size: 1.5rem;
    }
  }
}
