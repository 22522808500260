.add-package-modal {
  max-height: 92vh;
  width: 540px;
  box-shadow: 0px 4px 60px rgba(75, 75, 85, 0.1);
  border-radius: 24px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 6px;
  }
  &.scroll {
    &::-webkit-scrollbar {
      width: 6px;
    }
    &::-webkit-scrollbar-track {
      background-color: #616161;
      border-radius: 4px;
      margin: 4px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: rgb(70, 69, 69);
      border-radius: 4px;
      border: 3px solid rgba(153, 153, 153, 1);
    }
  }
}

@media only screen and (max-width: 780px) {
  .add-package-modal {
    width: 100%;
    margin: 0px;
  }
}
