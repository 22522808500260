/* hexagons thanks css-tricks */
.hexwrap {
  height: 0;
  overflow: hidden;
}
.hexes {
  display: flex;
  --hex-s: 100px; /* size  */
  --hex-m: 4px; /* margin */
  --hex-f: calc(1.732 * var(--hex-s) + 4 * var(--hex-m) - 1px);
  --hex-margin-bottom: calc(var(--hex-m) - var(--hex-s) * 0.2885);
  --hex-shape-outside: repeating-linear-gradient(
    #0000 0 calc(var(--hex-f) - 3px),
    #000 0 var(--hex-f)
  );

  position: absolute;
  z-index: -1;

  bottom: 150px;
}
.hex-container {
  font-size: 0; /*disable white space between inline block element */
  opacity: 0.5;
}
.hex-container div.hex {
  width: var(--hex-s);
  margin: var(--hex-m);
  height: calc(var(--hex-s) * 1.1547);
  display: inline-block;
  font-size: initial;
  clip-path: polygon(0% 25%, 0% 75%, 50% 100%, 100% 75%, 100% 25%, 50% 0%);
  // This is a fallback, it's inlined in the component to avoid postcss cssnano issues
  margin-bottom: var(--hex-margin-bottom);
}
.hex-container::before {
  content: '';
  width: calc(var(--hex-s) / 2 + var(--hex-m));
  float: left;
  height: 100%;
  // This is a fallback, it's inlined in the component to avoid postcss cssnano issues
  shape-outside: var(--hex-shape-outside);
}

@media (max-width: 950px) {
  .hexes {
    width: 90%;
  }
}
@media (min-width: 951px) {
  .hexes {
    width: 46%;
  }
}
