.multidrop-container {
  position: relative;
  user-select: none;
  display: flex;
  justify-content: flex-end;

  &.open {
    .multidrop-header {
      color: rgba(75, 75, 85, 0.5);
      justify-content: flex-start;
    }
  }

  .multidrop-header {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border: 1px solid rgb(223, 223, 223);
    background-color: white;
    cursor: pointer;
    width: 100%;
    min-height: 36px;
    max-height: 36px;
    box-shadow: 0px 1px 4px rgba(75, 75, 85, 0.1);
    padding: 0 12px;
    outline: none;
    span {
      display: flex;
      margin-right: 20px;
    }
    .mobile {
      display: none;
    }
    .desktop {
      display: inline-block;
    }
  }

  .multidrop-header-title {
    margin-right: 8px;
    font-size: 13px;
  }

  .filter-select-button > svg {
    color: #ff671d;
  }

  .multidrop-list {
    display: flex;
    flex: auto;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    min-width: 100%;
    max-height: 355px;
    border: 1px solid rgb(223, 223, 223);
    border-radius: 6px;
    box-shadow: 0 2px 5px -1px rgb(232, 232, 232);
    background-color: white;
    -webkit-overflow-scrolling: touch;
    .multidrop-list-title {
      padding-bottom: 12px;
      font-style: normal;
      font-weight: bold;
      font-size: 13px;
      line-height: 15px;
      color: rgba(75, 75, 85, 0.4);
    }
    .multidrop-list-item {
      display: inline-block;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
      text-overflow: ellipsis;
      text-align: left;
      font-weight: normal;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 800px) {
  .multidrop-container {
    margin-right: 5px;

    &.open {
      .multidrop-header {
        justify-content: center;
        width: 36px;
      }
    }
    .multidrop-header {
      height: 34px;
      width: 34px;
      padding: 0;
      .multidrop-header-title {
        display: none;
      }
      .desktop {
        display: none;
      }
      .mobile {
        display: inline-block;
        svg {
          height: 100%;
          width: 100%;
        }
      }
    }
    .multidrop-list {
      right: 0;
    }
  }
}
