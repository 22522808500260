.artwork-container {
  .artwork-thumbnail {
    width: 36px;
    height: 36px;
    min-width: 36px;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 6px;
  }

  .artwork-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #4b4b55;
    white-space: nowrap;
  }

  .add-artwork-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
  }

  .artwork-edit {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #ff671d;
  }
}

.artwork-container.selected {
  border-color: #ff671d;
}

.image-container {
  width: 218px;
  height: 218px;
  background: #ffffff;
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 8px;
}

@media only screen and (max-width: 768px) {
  .artwork-container {
    width: 100%;
  }

  .image-container {
    width: 100%;
    height: 300px;
  }
}
