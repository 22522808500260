.branding-footer-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-bottom: 0px;
}

.branding-footer-links {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  max-width: 800px;
  margin-bottom: 0px;

  a {
    font-weight: normal;
    font-size: 14px;
    line-height: 150%;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
  }
}

@media only screen and (max-width: 780px) {
  .branding-footer-links {
    flex-direction: column;
    padding-bottom: 20px;
  }

  .branding-footer-tagline {
    margin-bottom: 20px;
  }
}
