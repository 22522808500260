.create-edit-merchstore-modal {
  .react-responsive-modal-modal {
    border-radius: 24px;
    margin: 0;
    padding: 0;
    .card-wrapper {
      padding: 30px;
      width: 375px;
      position: relative;
    }
  }
}

p {
  &.success {
    color: #20c157;
  }

  &.error {
    color: #e2543e;
  }
}

@media only screen and (max-width: 768px) {
  .create-edit-merchstore-modal {
    .react-responsive-modal-modal {
      border-radius: 0;
      height: 100%;
      width: 100%;
      .card-wrapper {
        width: 100%;
        h2 {
          max-width: 158px;
        }

        button {
          height: 44px;
        }
      }
    }
  }
}
