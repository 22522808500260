table {
  width: 100%;
  height: 100%;
  display: table;

  thead {
    font-style: normal;
    font-weight: 500;
    text-align: center;
    color: rgba(75, 75, 85, 0.5);
    font-size: 14px;
    border-bottom: 1px;
    line-height: 16px;

    th {
      height: 60px;
    }

    th:first-child {
      padding-left: 16px;
    }

    th:last-child {
      padding-right: 16px;
    }

    .mobile {
      display: none;
    }
  }

  thead,
  tr {
    border-bottom: 1px solid #dddddd;
  }

  tbody {
    tr {
      height: 65px;
      &.grey {
        background: linear-gradient(0deg, #fbfbfb, #fbfbfb), #ffffff;
      }

      &.deleted {
        td.actions {
          display: flex;
          justify-content: center;
        }
      }

      td {
        height: 65px;
        text-align: center;
        font-size: 14px;
        color: #4b4b55;

        .bold {
          font-weight: bold;
        }

        .grey {
          color: rgba(75, 75, 85, 0.5);
        }

        .logo {
          margin: 0;
          margin-right: 8px;
          float: left;
        }

        img {
          max-height: 100%;
        }

        &.logo {
          // width: 46px;
          padding-left: 16px;
        }

        &.actions {
          width: 80px;
          padding-right: 16px;
          padding-left: 12px;
        }
      }

      td.mobile {
        display: none;
      }
    }
  }

  tfoot {
    tr {
      border-bottom: 0px;

      td {
        div {
          background-color: #fafafa;
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }

    .footer-container {
      height: 60px;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .pagination-count {
        font-weight: normal;
        font-size: 13px;
        line-height: 14px;
        color: rgba(75, 75, 85, 0.6);
      }

      .pagination-arrows {
        border: 1px solid #dddddd;
        button {
          height: 28px;
          width: 28px;
          display: flex;
          justify-content: center;
          align-items: center;
          background: #ffffff;

          &.disabled {
            cursor: auto;
            &:hover {
              cursor: auto;
              background: #ffffff;
            }
            svg {
              color: #cccccc;
            }
          }

          svg {
            color: rgb(75, 75, 85);
          }

          &.left {
            border-right: 1px solid #dddddd;
          }

          &:hover {
            background: linear-gradient(
                0deg,
                rgba(75, 75, 85, 0.05),
                rgba(75, 75, 85, 0.05)
              ),
              #ffffff;
            cursor: pointer;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  table {
    border-top-left-radius: 18px;
    border-top-right-radius: 18px;
    height: 100%;
    thead {
      text-align: left;
      .desktop {
        display: none;
      }

      .mobile {
        display: table-row;
        font-weight: normal;
        font-size: 12px;
        background-color: #ffffff;
        height: 41px;

        th {
          padding-left: 20px;
        }
      }
    }

    tbody {
      tr {
        td {
          img {
            height: 34px;
          }
        }
        td.desktop {
          display: none;
        }
        td.mobile {
          display: block;
        }
      }
    }

    tfoot {
      height: 60px;

      tr {
        height: 100%;
        display: table-row;
        td {
          height: 100%;
          .footer-container {
            width: 100%;

            .pagination-count {
              justify-content: center;
            }
          }
        }
      }
    }
  }
}
