.member-list-container {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  margin-top: 5px;
}

.member-item {
  height: 68px;
  display: flex;
  align-items: center;
}

.member-icon {
  border-radius: 100px;
  width: 12px;
  height: 12px;
}

.green {
  background: #20ae1d;
}

.yellow {
  background: #f1cf1d;
}

.member-checkbox {
  margin-left: 14px;
}

.memberSelector {
  width: 42px;
}

.memberEmail {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 14px;
  line-height: 16px;
  color: #4b4b55;
  text-align: left;
}

.memberStatus {
  display: flex;
  justify-content: center;
}

.memberId {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
  text-align: right;
  letter-spacing: 0.05em;
  color: rgba(75, 75, 85, 0.5);
}

.filter-dropdowns {
  display: flex;
  flex-direction: row;
}

.filters-chosen-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  min-height: 0px;
  max-height: 100px;
  max-width: 1180px;
  width: 100%;
  margin: 10px auto;
}

.filters-chosen {
  margin: 3px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #4b4b55, 100%;
  background-color: #dbdbe1;
}

label[for='edit-admin'] {
  color: rgba(75, 75, 85, 0.5);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%; /* 18px */
  margin-bottom: 10px;
}

@media only screen and (max-width: 768px) {
  .member-list-container {
    width: auto;
    border-radius: 0;
  }

  .memberStatus {
    display: table-cell;
  }

  .actionsMobile {
    display: flex !important;
    justify-content: center;
  }
}

.admin-label {
  display: flex;
  padding: 6px 8px 5px 8px;
  align-items: center;
  gap: 10px;
  border-radius: 100px;
  background: rgba(221, 221, 221, 0.5);
  color: rgba(75, 75, 85, 0.5);
  text-align: center;
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.3px;
  text-transform: uppercase;
}
