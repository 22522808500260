.active-orders-icon {
  border-radius: 100px;
  width: 0.6rem;
  height: 0.6rem;
}

.orange {
  background: #f26522;
}

.merchstores-container {
  display: flex;
  justify-content: center;
  table {
    .actions {
      // Adjust dropdown width
      width: 120px;

      button {
        height: 36px;
        width: 85px;
        font-size: 12px;
      }

      .dd-list.dd-width-120 {
        // Adjust dropdown right position
        margin-right: 58px;
      }
    }
    tr.closed {
      td.logo,
      td.groupAdmin,
      td.closeDate,
      td.revenue,
      td.orderCount {
        opacity: 0.5;
      }
    }
  }
}

@media only screen and (max-width: 780px) {
  .group-orders-container {
    flex-grow: 1;
    table {
      .actions {
        width: 36px;
        button {
          width: 36px;
        }
      }
    }
  }
}
