.sidebarMenu {
  z-index: 3;
  min-height: 100vh;
  margin-right: 80px;
  .sidebarMenu-bars {
    display: none;
  }

  .nav-menu {
    background: #4b4b55;
    width: 80px;
    height: 100%;
    position: fixed;
    .faqs-link {
      bottom: 50px;
    }
    .logout-link {
      bottom: 24px;
    }
  }

  .logo {
    height: 80px;
    background: #021d49;
    cursor: pointer;

    img {
      height: 64px;
    }
  }

  .menu-item-active {
    border-right: 3px solid #ff671d;
  }

  .menu-item-icon {
    width: 44px;
    height: 44px;
    background: #dddddd;
    box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    margin-left: 18px;
    cursor: pointer;

    svg {
      top: inherit;
      right: inherit;
      color: #999999;
    }
  }

  .menu-item-icon.active {
    background: #ffffff;

    svg {
      color: #ff671d;
    }
  }

  .closeButton {
    display: none;
    z-index: 3;
  }
}

@media only screen and (max-width: 768px) {
  .sidebarMenu {
    width: 0px;
    transition: 850ms;
    margin-right: 0;

    .sidebarMenu-bars {
      display: block;
      color: #000;
      font-size: 30px;
      position: absolute;
      top: 16px;
      left: 16px;
    }

    .nav-menu {
      background: #4b4b55;
      position: fixed;
      top: 0;
      left: -100px;
      transition: 850ms;
      width: 60px;
    }

    .nav-menu.active {
      left: 0;
      transition: 350ms;
    }

    .closeButton {
      display: block;
      position: absolute;
      top: 10px;
      left: 70px;
      font-size: 30px;
      color: #000;
    }

    .menu-item-icon {
      width: 36px;
      height: 36px;
      margin-left: 12px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    .logo {
      height: 60px;
    }
  }

  .sidebarMenu.active {
    width: 60px;
    transition: 350ms;
    margin-right: 0;

    .sidebarMenu-bars {
      display: none;
    }
  }
}
