.package-list-container {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 12px;
}

.package-item {
  height: 68px;
  display: flex;
  align-items: center;
}

.packageLogo {
  padding-left: 20px;
  width: 76px;
}

.actions {
  font-size: 12px;
}

.actionDelete {
  width: 50px;
}

.logoShadow {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 6px;
  background-color: white;

  &.top {
    top: -2px;
    left: 0;
    width: 50px;
    height: 50px;
  }

  &.first {
    top: 0px;
    left: 3px;
    width: 50px;
    height: 45px;
  }

  &.second {
    top: 2px;
    left: 6px;
    width: 50px;
    height: 40px;
  }
}

@media only screen and (max-width: 768px) {
  .package-list-container {
    width: auto;
    border-radius: 0;
  }

  .actionsMobile {
    display: flex !important;
    justify-content: center;
  }
}
