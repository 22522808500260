.domain-modal {
  margin: 0 !important;
  padding: 30px;
  border-radius: 24px;
  max-height: 711px;
  height: 100%;
  font-family: Roboto;
  width: 100%;
  max-width: 420px;

  h2 {
    font-weight: bold;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 23px;
    margin-bottom: 30px;
    margin-top: 10px;
    color: #4b4b55;
  }
  .wrapper {
    width: 100%;
    position: relative;
  }
  .svg-location {
    position: absolute;
    right: 10px;
    top: 14px;
  }
  .react-responsive-modal-closeButton {
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    margin-top: 25px;
    margin-right: 10px;
    outline: none;
    background: rgba(75, 75, 85, 0.15);
    border-radius: 40px;
  }
  .underline {
    position: absolute;
    width: 360px;
    height: 0px;
    border: 1px solid #dddddd;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 5px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(153, 153, 153, 1);
    border-radius: 4px;
    border: 3px solid rgba(153, 153, 153, 1);
  }
}
summary::-webkit-details-marker {
  display: none;
}
details > summary:first-of-type {
  list-style-type: none;
}
summary::after {
  float: right;
  content: url(../../assets/common/arrow_down.svg);
}
details[open] summary:after {
  float: right;
  content: url(../../assets/common/arrow_up.svg);
}
