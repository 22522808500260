.product-title {
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 125%;
  color: #4b4b55;
}
.view-edit-product-container {
  box-sizing: border-box;

  .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 21px;
    color: #4b4b55;
  }

  .subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #4b4b55;
  }
}

.variant-table-container {
  border: 1px solid #dddddd;
  box-sizing: border-box;
  border-radius: 12px;
  width: 100%;
  margin-top: 5px;
  background-color: #ffffff;
}

#product-edit-description {
  .ql-toolbar {
    border-radius: 8px 8px 0 0;
  }
  .ql-container {
    border-radius: 0 0 8px 8px;
  }
}
