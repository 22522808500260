form {
  .form-input-container {
    margin-bottom: 12px;
  }
  .two-inputs-60-40 {
    .form-input-container:first-child,
    .form-select-container {
      width: 60%;
      margin-right: 10px;
    }
    .form-input-container:last-child {
      max-width: 40%;
    }
  }
  .two-inputs-50-50 {
    .form-input-container:first-child {
      margin-right: 10px;
      width: 50%;
    }
    .form-input-container:last-child {
      max-width: 50%;
    }
  }
}
