.view-edit-product-container {
  .artwork-container {
    width: 218px;
    height: 60px;
    background: #ffffff;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    box-shadow: 0px 1px 3px rgba(75, 75, 85, 0.1);
    border-radius: 8px;

    .artwork-thumbnail {
      width: 36px;
      height: 36px;
      min-width: 36px;
      border: 1px solid #dddddd;
      box-sizing: border-box;
      border-radius: 6px;
    }

    .artwork-title {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      color: #4b4b55;
    }
  }

  .artwork-container.selected {
    border-color: #ff671d;
  }

  .image-container {
    width: 218px;
    height: 218px;
    background: #ffffff;
    border: 1px solid #dddddd;
    box-sizing: border-box;
    border-radius: 8px;
  }

  @media only screen and (max-width: 768px) {
    .artwork-container {
      width: 100%;
    }

    .image-container {
      width: 100%;
      height: 300px;
    }
  }
}

.image-modal {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 24px;
  max-height: 720px;
  max-width: 720px;
}

.view-edit-product-container.scrollable {
  height: 60vh;
  overflow-y: scroll;

  -webkit-overflow-scrolling: touch;
  &::-webkit-scrollbar {
    width: 5px;
  }
  &::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 5px;
    margin-top: 35px;
    margin-bottom: 35px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: rgba(153, 153, 153, 1);
    border-radius: 4px;
    border: 3px solid rgba(153, 153, 153, 1);
  }
}
