.shipping-form-modal {
  margin: 0 !important;
  padding: 0 !important;
  border-radius: 24px;
  min-height: 420px;
  min-width: 420px;
  max-height: 100vh;
  overflow-y: scroll;

  h2 {
    margin-top: 30px;
    margin-left: 30px;
    font-weight: bold;
  }

  .react-responsive-modal-closeButton {
    height: 32px;
    width: 32px;
    justify-content: center;
    align-items: center;
    top: 30px;
    outline: none;
    background: rgba(75, 75, 85, 0.15);
    border-radius: 40px;
  }
}

.shipping-form-container {
  &.loading {
    display: none;
  }

  form {
    padding-left: 30px;
    padding-right: 30px;
    padding-bottom: 30px;
    padding-top: 25px;

    .focus {
      label:not(#label-select-package-dropdown):not(
          .primary-submission-dropdown-label
        ) {
        top: 7px !important;
        font-size: 11px !important;
      }
      input {
        &:not(.btn) {
          padding-top: 21px !important;
        }
      }
    }

    label {
      display: inline-block;
      font-weight: bold;
      font-size: 14px;
      margin-bottom: 12px;

      &.hs-form-booleancheckbox-display {
        display: flex;
      }

      span {
        font-weight: normal;
        font-size: 13px !important;
      }
    }

    .field {
      margin-bottom: 20px;
      position: relative;

      &.hs_firstname,
      &.hs_group_order_tool_shipping_country,
      &.hs_group_order_tool_shipping_state {
        margin-right: 10px;
      }

      .artwork-preview {
        width: 28px;
        height: 28px;
        position: absolute;
        top: 8px;
        left: 10px;
        border: 1px solid #dddddd;
        border-radius: 6px;
        padding: 2px;
      }
    }

    .btn {
      padding: 0;
    }
    .input {
      input {
        width: 100% !important;
        padding: 0 0 0 15px;
        font-size: 14px;
        background: #ffffff;
        border: 1px solid #dddddd;
        height: 45px;
        border-radius: 8px;
        font-weight: 500;

        &.selected-package-input {
          color: rgba(75, 75, 85, 0.5);
        }
      }
    }

    select {
      width: 100%;
      border: 1px solid #dddddd;
      border-radius: 8px;
      height: 45px;
      padding-left: 14px;
      font-size: 14px;
      padding-top: 21px;
      font-weight: 500;
      -webkit-appearance: initial;
      -moz-appearance: none;
      background: transparent;
      background-image: url("data:image/svg+xml;utf8,<svg stroke='currentColor' fill='currentColor' stroke-width='0' viewBox='0 0 24 24' height='1em' width='1em' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'></path></svg>");
      background-repeat: no-repeat;
      background-position-x: 96%;
      background-position-y: 14px;

      &.one-option {
        background-image: none;
      }
    }

    .hs-item_submissions_artwork,
    .hs_item_submissions,
    .hs_styles {
      label {
        position: absolute;
        font-weight: 500;
        font-size: 11px;
        line-height: 13px;
        color: rgba(75, 75, 85, 0.5);
        margin-left: 15px;
        margin-top: 7px;
      }
      select {
        font-size: 14px;
      }
    }
    .hs-item_submissions_artwork {
      label {
        margin-left: 49px;
        margin-top: 7px;
      }

      select {
        padding-left: 45px;
        padding-top: 21px;
      }
    }

    .hs_selected_package {
      .selected-package-input-container {
        position: relative;

        .edit-package {
          position: absolute;
          right: 20px;
          top: 16px;
          font-size: 12px;
          line-height: 14px;
          color: #ff671d;
          font-weight: bold;
          cursor: pointer;
        }
      }
      select:not(#user-package-select-dropdown) {
        padding-top: 0;
      }
    }

    .hs_submit {
      input {
        width: 100% !important;
      }
    }

    .actions {
      display: flex;
    }
    .error-message {
      color: #e2543e;
      font-size: 13px;
      margin-left: 5px;
    }

    .did-you-mean-div {
      color: rgba(75, 75, 85, 1);
      font-weight: bold;

      a {
        color: #ff671d;
        font-weight: bold;
        text-decoration: underline;
        cursor: pointer;
      }
    }
  }

  .submitted-message {
    padding: 30px;
    text-align: center;
    p {
      font-size: 16px;
      strong {
        font-size: 18px;
      }
    }
    p:nth-child(2) {
      padding-top: 8px;
    }

    .thank-you-message {
      font-weight: bold;
      font-size: 18px;
      margin-top: 30px;
    }

    .confirmation-email-message {
      margin-top: 8px;
      font-size: 16px;
    }

    .cta-container {
      font-size: 14px;
      margin-top: 23px;
      line-height: 16px;

      button {
        height: 44px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .shipping-form-modal {
    border-radius: 0;
    .card-wrapper > :first-child {
      border-radius: 0;
    }
  }
}
