.group-orders-container {
  display: flex;
  justify-content: center;
  table {
    .actions {
      button {
        height: 36px;
        width: 80px;
        font-size: 12px;
      }
    }
    tr.closed {
      td.logo,
      td.groupAdmin,
      td.groupOrderId,
      td.openDate,
      td.closeDate {
        opacity: 0.5;
      }
    }
  }
}

.dropdowns-container {
  width: 97%;
  max-width: 1250px;
  max-height: 36px;
  margin: 0 auto;
}

.filter-dropdowns {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
}

.sort-orders-container {
  max-width: 870px;
  display: flex;
  justify-content: flex-end;
}

.filters-chosen-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  min-height: 0px;
  max-height: 100px;
  max-width: 1250px;
  width: 97%;
  margin: 10px auto;
}

.filters-chosen {
  margin: 3px 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
  font-weight: 500;
  color: #4b4b55, 100%;
  background-color: #dbdbe1;
}

@media only screen and (max-width: 780px) {
  .group-orders-container {
    flex-grow: 1;
    table {
      .actions {
        width: 36px;
        button {
          width: 36px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1230px) {
  .filters-chosen-container {
    margin-top: 10px;
    margin-bottom: 10px;
  }
}
