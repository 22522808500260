.main-container {
  z-index: 2;
  overflow: overlay;
}

@media only screen and (max-width: 768px) {
  .main-container {
    transition: 0.5s ease-in-out;
    flex-shrink: 0;
  }
}
