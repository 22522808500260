.submission-thank-you-container {
  width: 295px;
  height: 367px;

  .thumb-container {
    height: 98px;
    width: 98px;
    background: #e8eef0;
    border-radius: 50%;
  }

  .bottom-note {
    color: #999999;
    font-size: 12px;
  }
}
