.hs-error-msg {
  color: #c61717 !important;
  font-style: normal;
  font-weight: normal !important;
  font-size: 13px !important;
  padding-left: 5px;
  margin-bottom: 0 !important;
}

.hs-form-required {
  display: none;
}

.hs-input {
  &.error {
    border: 1px solid rgba(198, 23, 23, 0.5) !important;
  }
}
