@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl;
  }
  h2 {
    @apply text-xl;
  }
  @media only screen and (max-width: 768px) {
    h1 {
      @apply text-xl;
    }
  }
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-family: 'Roboto';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ebebf0;
  color: #4b4b55;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.form-row {
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.form-row label {
  margin-bottom: 12px;
}

p,
select,
label,
ul,
li {
  font-size: 14px;
}

/* Button style start */

.buttons-container {
  display: flex;
  justify-content: space-between;
}

.buttons-container.group {
  box-sizing: border-box;
  box-shadow: 0px 1px 3px rgba(75, 75, 85, 0.1);
  border-radius: 6px;
  overflow: hidden;
}

.buttons-container.group button {
  border-radius: 0%;
  box-shadow: none;
  padding: 0;
}

.buttons-container.group button:first-child {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
}

.buttons-container.group button:last-child {
  border-top-right-radius: 6px;
  border-bottom-right-radius: 6px;
}

/* Button style start */
.btn {
  box-shadow: 0px 5px 10px rgba(75, 75, 85, 0.15);
  border-radius: 8px;
  color: #fff;
  padding: 14px;
  height: 44px;
}

.btn.primary {
  background: #ff671d;
  color: #fff;
}

.btn.primary:hover {
  background: linear-gradient(
      0deg,
      rgba(75, 75, 85, 0.1),
      rgba(75, 75, 85, 0.1)
    ),
    #ff671d;
}

.btn.secondary {
  color: #4b4b55;
  border: 1px solid #dddddd;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
}

.btn:disabled,
.btn[disabled],
.btn:disabled:hover,
.btn[disabled]:hover {
  background: #dddddd;
  color: #999999;
  cursor: default;
}

/* button svg {
  margin-left: 5px;
} */

@media only screen and (max-width: 768px) {
  button.primary,
  button.secondary {
    height: 34px;
  }

  button.primary span,
  button.secondary span {
    display: none;
  }

  button.primary svg,
  button.secondary svg {
    margin: 0;
  }
}
/* Button style end */

/* Links style start */
a {
  color: rgba(75, 75, 85, 0.5);
  text-decoration: none;
  font-weight: bold;
  font-size: 12px;
}

a.link-2 {
  color: inherit;
  font-weight: inherit;
  font-size: inherit;
  text-decoration: underline;
}

a.link-3 {
  color: #ff671d;
  font-weight: bold;
  font-size: 14px;
}
/* Links style end */

/* Form style start */

form input[type='text']:not(.input-type-two),
form input[type='email']:not(.input-type-two),
form input[type='password']:not(.input-type-two) {
  padding: 20px 0 7px 15px;
}

form input.error {
  border: 1px solid rgba(198, 23, 23, 0.5);
}

form span.error {
  color: #c61717;
  font-size: 12px;
  margin: 5px 0 0 5px;
}

/* Form style end */

span.success {
  color: #13992c;
  font-size: 14px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  #root {
    padding: 0;
  }
}
