.order-details-sidebar-menu {
  .title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 125%;
    color: #4b4b55;
    border-bottom: 1px solid #dddddd;
  }

  .item-text {
    font-family: Roboto;
    font-style: normal;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
  }

  .item-value {
    color: #4b4b55;
  }
}

.order-details {
  .close-date {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #999999;
  }

  .section-title {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 16px;
    color: #4b4b55;
  }

  .section-subtitle {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 13px;
    line-height: 21px;
    color: #999999;
  }

  .section-info {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    color: #4b4b55;
  }

  .gallery-container .main-image {
    padding: 0;

    .full-screen-icon {
      right: 0;
      top: 0;
    }
  }
}

.Tooltip-Tip.tooltip-tip-out-of-stock {
  &.top {
    bottom: 3.5rem;
    left: -65%;

    .arrow {
      left: calc(45%);
    }
  }

  &.left {
    top: 30%;
    left: auto;
    right: calc(100% - 0.5rem);
  }
}
