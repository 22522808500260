.checkbox-container {
  display: flex;
  vertical-align: middle;

  .checkbox-hidden {
    border: 0;
    -webkit-clip: rect(0 0 0 0);
    clip: rect(0 0 0 0);
    -webkit-clippath: inset(50%);
    clip-path: inset(50%);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px !important;
  }

  .checkbox-styled {
    display: inline-block;
    transition: all 150ms;
    width: 20px;
    height: 20px;
    background: #ffffff;
    border: 1px solid #dddddd;
    border-radius: 6px;

    .checkbox-check-icon {
      fill: none;
      stroke: #ff671d;
      stroke-width: 2px;
      position: static;
    }
  }

  .checkbox-label {
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 13px;
    line-height: 15px;
    color: #4b4b55;
    margin: 0 8px 0 8px;
  }

  .visible {
    visibility: visible;
  }

  .invisible {
    visibility: hidden;
  }
}
